@media print {
  
  .modal-dialog {
    max-width: 100% !important; /* Make the modal full width */
    margin: 0 !important;       /* Remove margins */
  }

  .modal-content {
    overflow: visible !important;
    padding: 0 !important;      /* Remove padding from the modal content */
    border: none !important;    /* Remove borders if needed */
  }

  .modal-header,
  .modal-footer {
    display: none !important;   /* Optionally hide header and footer */
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  .print-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .print-header {
    position: fixed;
    top: 0;
   
    right: 0;
    background-color: white;
    padding: 5px;
    padding-left: 25px;
    padding-top: 25px;
  }
  .print-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    font-size: xx-small;
    padding-top: 5px;
    padding-bottom: 0px;
    
  }
  .print-header, .print-footer {
    position: static;
  }
  .print-content {
    flex: 1;
    margin-top: 10px;/* 
    padding-top: 50px; */ /* Adjust based on your footer height */
    padding-left: 20px;
    padding-right: 20px;
    overflow: visible;
   /*  display: block !important; */
  }
  .inv-content {
    padding-top: 15px;/* 
    padding-top: 60px; */
    padding-left: 20px;
    padding-right: 20px;/* Adjust based on your footer height */
    /* overflow: visible; */
  }
  
  @page {
    margin: 0;
  }
  
  /* Ensure footer appears on every page */
  .print-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    page-break-after: always;
  }
  
  /* Adjust content to avoid overlapping with footer */
  .print-content,
  .inv-content {
    margin-bottom: 0px; /* Adjust based on your footer height */
  }

/* Rest of your CSS remains unchanged */
.terms-table {
  table-layout: fixed;
  padding: 0px;
  margin: 0px;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.65rem;
  /* Slightly reduced font size */
}

.terms-table th,
.terms-table td {
  border: 1px solid #ddd;
  padding: 6px;
  /* Reduced padding */
  vertical-align: center;
  box-sizing: border-box;
}

.terms-table th {
  background-color: #f2f2f2;
}

.terms-table td {
  width: 50%;
  /* Ensure equal width for both columns */
}

.terms-table td:first-child {
  text-align: left;
  /* Align French text to the left */
}

.terms-table .arabic-terms {
  direction: rtl;
  text-align: right;
  /* Align Arabic text to the right */
}

.terms-table ul {
  padding-left: 15px;
  /* Reduced left padding for lists */
  margin-bottom: 0;
  margin-top: 3px;
  /* Add a small top margin */
}

.terms-table .arabic-terms ul {
  padding-right: 15px;
  /* Reduced right padding for Arabic lists */
  padding-left: 0;
}

.terms-table li {
  margin-bottom: 1.5px;
  /* Reduced space between list items */
  line-height: 1;
  /* Reduced line height */
  font-size: x-small;
}

.terms-container ul,
.terms-container ol {
  margin-bottom: 5px;
}

.terms-container li {
  margin-bottom: 2px;
}

.terms-container {
  flex: 1;
  padding-top: 10px;
  
  
}

.page-break {
  page-break-before: always;
}

}



.signature-line {
  border-bottom: .5px solid #000;
  width: 80%;
  margin-top: 1.5rem;
  /* Slightly reduced top margin */
}

.rounded-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  overflow: hidden;
}

.rounded-table td {
  border: 1px solid #dee2e6;
}

.rounded-table tr:first-child td:first-child {
  border-top-left-radius: 0.25rem;
}

.rounded-table tr:first-child td:last-child {
  border-top-right-radius: 0.25rem;
}

.rounded-table tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem;
}

.rounded-table tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem;
}
.vertical-line {
  border-right: 1px solid #000; /* Adjust the color and thickness as needed */
  height: 80%; /* Ensure the line spans the full height of the column */
}